<template>
  <categories-form
    v-if="category.id"
    ref="categories-form"
  />
  <component-not-found
    v-else-if="error"
    title="Categories List"
    :button-link="{name: 'settings-categories-list'}"
  />
  <skeleton v-else />
</template>

<script>
import config from '@/views/settings/fulfillment/warehouses/warehouseConfig'
import CategoriesForm from '@/views/settings/catalog/categories/components/CategoriesForm.vue'
import { mapGetters } from 'vuex'
import Skeleton from '../components/Skeleton.vue'

export default {
  name: 'CategoriesUpdate',
  components: { CategoriesForm, Skeleton },
  data() {
    return {
      error: false,
    }
  },

  computed: {
    category() {
      return this.$store.state[this.MODULE_NAME].categoryForm
    },
    ...mapGetters('listModule', ['getStatus']),
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['categories-form']) this.$refs['categories-form'].showConfirmationModal(next)
  },
  mounted() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const category = this.mappingFields(Object.keys(this.fields), data, {
        id: data.id,
        name: data.name,
        is_active: data.is_active,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, category)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_CATEGORY_FORM_CLONE`, category)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  setup() {
    const MODULE_NAME = 'settings-catalog-categories'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
