<template>
  <div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ $t('Update a Category') }}
    </p>
    <b-row class="py-2">
      <b-col md="6">
        <div
          v-for="field in ['name']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ fields[field].label }}</span>
          <b-skeleton-img
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <label class="font-weight-bolder ml-1">Status</label>
        <div
          v-for="field in ['is_active']"
          :key="field"
          style="height: 30px"
          class="d-flex align-items-center justify-content-between"
        >
          <span class="ml-1">{{ fields[field].label }}</span>
          <b-skeleton-img
            height="18px"
            width="18px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BSkeletonImg, BCol,
} from 'bootstrap-vue'
import config from '../CategoriesConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeletonImg,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
